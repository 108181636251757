import React from "react"
import Layout from "../layout"

import "../css/intro.css"
import "../css/about.css"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function About() {
  // TODO: does this load the right sizes; e.g. 100% of container or 100vw of container?
  const images = useStaticQuery(graphql`
    {
      chairs: file(relativePath: { eq: "about/chairs@2x.jpg" }) {
        ...jpgFragment
      }

      michellejon: file(
        relativePath: { eq: "about/MichelleandJonWeddingPrint-324.jpg" }
      ) {
        ...jpgFragment
      }

      door: file(relativePath: { eq: "about/front-door@2x.jpg" }) {
        ...jpgFragment
      }

      outside: file(relativePath: { eq: "about/kyle-mel-wedding-0451.jpg" }) {
        ...jpgFragment
      }

      space1: file(relativePath: { eq: "about/space-1@2x.jpg" }) {
        ...jpgFragment
      }

      space2: file(relativePath: { eq: "about/space-2@2x.jpg" }) {
        ...jpgFragment
      }

      openhouse: file(relativePath: { eq: "about/openhouse@2x.jpg" }) {
        ...jpgFragment
      }
    }
  `)

  return (
    <Layout>
      <header className="FloatingHeader FloatingHeader--ctr">
        <h1>About</h1>
      </header>
      <section className="Intro Intro--wedding Intro--about">
        <div>
          <figure>
            <Img
              fluid={{
                ...images.door.childImageSharp.fluid,
              }}
            />
          </figure>
          <p>
            Second&nbsp;Floor&nbsp;Events easily adapts to both formal and
            casual events. As a full-service venue, it offers everything from
            premium in-house catering to professional event staff to serve and
            bartend.
          </p>
          <p>
            Every Second&nbsp;Floor&nbsp;Event client is also attended to by a
            dedicated Venue Specialist who oversees all in-house services from
            booking date through event day.
          </p>
          <p>
            Located in the heart of downtown Toronto,
            Second&nbsp;Floor&nbsp;Events hosts Corporate Events, Weddings, Team
            meetings, Photoshoots, Trunk shows and more.
          </p>
        </div>
        <div>
          <p className="Section__intro">
            Welcome to Second&nbsp;Floor&nbsp;Events, an open-concept 3,000
            square foot space with endless opportunity to customize. This
            renovated heritage property boasts 8-foot windows, original hardwood
            floors, customizable lightboxes, a snack bar, built-in DJ booth,
            36-foot bar and more.
          </p>
          <figure>
            <Img fluid={images.outside.childImageSharp.fluid} />
          </figure>
        </div>
      </section>

      <div className="About-Images">
        <div>
          <figure>
            <Img
              fluid={{
                ...images.space1.childImageSharp.fluid,
              }}
            />
          </figure>
          <figure>
            <Img fluid={images.space2.childImageSharp.fluid} />
          </figure>
        </div>
        <div>
          <figure>
            <Img fluid={images.michellejon.childImageSharp.fluid} />
          </figure>
        </div>
      </div>
      <div className="">
        <Img fluid={images.openhouse.childImageSharp.fluid} />
      </div>
    </Layout>
  )
}
